"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinancialStatusValidator = void 0;
var FinancialStatusValidator = function (payload, getCurrentLang) {
    if (payload.financial_info_id === null) {
        return {
            message: 'required',
            field: 'financial_info',
            status: 'error',
        };
    }
    else if (payload.profit_formula === '') {
        return {
            message: 'required',
            field: 'profit_formula',
            status: 'error',
        };
    }
    else if (JSON.parse(payload.profit_message)[getCurrentLang] === '') {
        return {
            message: 'required',
            field: 'profit_message',
            status: 'error',
        };
    }
    else if (payload.loss_formula === '') {
        return {
            message: 'required',
            field: 'loss_formula',
            status: 'error',
        };
    }
    else if (JSON.parse(payload.loss_message)[getCurrentLang] === '') {
        return {
            message: 'required',
            field: 'loss_message',
            status: 'error',
        };
    }
    else if (payload.neutral_formula === '') {
        return {
            message: 'required',
            field: 'neutral_formula',
            status: 'error',
        };
    }
    else if (JSON.parse(payload.neutral_message)[getCurrentLang] === '') {
        return {
            message: 'required',
            field: 'neutral_message',
            status: 'error',
        };
    }
    else if (payload.roi === '') {
        return {
            message: 'required',
            field: 'roi',
            status: 'error',
        };
    }
    else if (payload.pbp === '') {
        return {
            message: 'required',
            field: 'pbp',
            status: 'error',
        };
    }
    else {
        return {
            message: 'success',
            field: '',
            status: 'success',
        };
    }
};
exports.FinancialStatusValidator = FinancialStatusValidator;
