"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@apollo/client");
var Mutation_1 = require("../components/Admin/MyFinance/Graphql/Mutation");
function useFinancialStatus() {
    var callFinancialStatusSetting = (0, client_1.useMutation)(Mutation_1.UPSERT_FINANCIAL_STATUS_SETTING, {
        fetchPolicy: 'network-only',
    })[0];
    return callFinancialStatusSetting;
}
exports.default = useFinancialStatus;
