"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_SERVICE_DETAIL = exports.DELETE_SERVICE_DETAIL = exports.DELETE_SERVICE_CATEGORY = exports.UPSERT_SERVICE_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var UPSERT_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation bulkUpsertServiceCategory($input: [addServiceCategoryPayload]) {\n    bulkUpsertServiceCategory(input: $input)\n  }\n"], ["\n  mutation bulkUpsertServiceCategory($input: [addServiceCategoryPayload]) {\n    bulkUpsertServiceCategory(input: $input)\n  }\n"])));
exports.UPSERT_SERVICE_CATEGORY = UPSERT_SERVICE_CATEGORY;
var DELETE_SERVICE_CATEGORY = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation deleteServiceCategory($id: ID, $organization_id: ID) {\n    deleteServiceCategory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteServiceCategory($id: ID, $organization_id: ID) {\n    deleteServiceCategory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_SERVICE_CATEGORY = DELETE_SERVICE_CATEGORY;
var UPSERT_SERVICE_DETAIL = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation upsertServiceDetail(\n    $organization_id: ID!\n    $service_detail_id: ID\n    $service_category_id: ID!\n    $name: String!\n    $service_media: ServiceMediaInput!\n    $sorting: Int\n    $description: String\n    $phone: String\n    $website: String\n    $email: String\n    $address: String\n  ) {\n    upsertServiceDetail(\n      organization_id: $organization_id\n      service_detail_id: $service_detail_id\n      service_category_id: $service_category_id\n      name: $name\n      service_media: $service_media\n      sorting: $sorting\n      description: $description\n      phone: $phone\n      website: $website\n      email: $email\n      address: $address\n    )\n  }\n"], ["\n  mutation upsertServiceDetail(\n    $organization_id: ID!\n    $service_detail_id: ID\n    $service_category_id: ID!\n    $name: String!\n    $service_media: ServiceMediaInput!\n    $sorting: Int\n    $description: String\n    $phone: String\n    $website: String\n    $email: String\n    $address: String\n  ) {\n    upsertServiceDetail(\n      organization_id: $organization_id\n      service_detail_id: $service_detail_id\n      service_category_id: $service_category_id\n      name: $name\n      service_media: $service_media\n      sorting: $sorting\n      description: $description\n      phone: $phone\n      website: $website\n      email: $email\n      address: $address\n    )\n  }\n"])));
exports.UPSERT_SERVICE_DETAIL = UPSERT_SERVICE_DETAIL;
var DELETE_SERVICE_DETAIL = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation deleteServiceDetail(\n    $id: ID!\n    $organization_id: ID!\n    $service_category_id: ID!\n  ) {\n    deleteServiceDetail(\n      id: $id\n      organization_id: $organization_id\n      service_category_id: $service_category_id\n    )\n  }\n"], ["\n  mutation deleteServiceDetail(\n    $id: ID!\n    $organization_id: ID!\n    $service_category_id: ID!\n  ) {\n    deleteServiceDetail(\n      id: $id\n      organization_id: $organization_id\n      service_category_id: $service_category_id\n    )\n  }\n"])));
exports.DELETE_SERVICE_DETAIL = DELETE_SERVICE_DETAIL;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
