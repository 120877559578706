"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SERVICE_CATEGORY_BY_ORGANIZATION = void 0;
var client_1 = require("@apollo/client");
var GET_SERVICE_CATEGORY_BY_ORGANIZATION = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getAllServiceCategoryByOrganization($organization_id: ID!) {\n    getAllServiceCategoryByOrganization(organization_id: $organization_id) {\n      id\n      organization_id\n      name\n      icon\n      icon_web\n      sorting\n      service_details {\n        id\n        name\n        icon\n        thumbnail\n        video\n        icon_web\n        video_web\n        thumbnail_web\n        description\n        phone\n        email\n        website\n        sorting\n        service_category_id\n        address\n        organization_id\n      }\n    }\n  }\n"], ["\n  query getAllServiceCategoryByOrganization($organization_id: ID!) {\n    getAllServiceCategoryByOrganization(organization_id: $organization_id) {\n      id\n      organization_id\n      name\n      icon\n      icon_web\n      sorting\n      service_details {\n        id\n        name\n        icon\n        thumbnail\n        video\n        icon_web\n        video_web\n        thumbnail_web\n        description\n        phone\n        email\n        website\n        sorting\n        service_category_id\n        address\n        organization_id\n      }\n    }\n  }\n"])));
exports.GET_SERVICE_CATEGORY_BY_ORGANIZATION = GET_SERVICE_CATEGORY_BY_ORGANIZATION;
var templateObject_1;
