"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFilename = exports.reponseWithCsv = void 0;
var reponseWithCsv = function (data) {
    var getName = GetFilename(data);
    var link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', getName + '.xlsx');
    document.body.appendChild(link);
    link.click();
    return data;
};
exports.reponseWithCsv = reponseWithCsv;
function GetFilename(url) {
    if (url) {
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
            return m[1];
        }
    }
    return '';
}
exports.GetFilename = GetFilename;
