"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_GUIDE_CATEGORY = exports.GET_GUIDE = void 0;
var client_1 = require("@apollo/client");
var GET_GUIDE_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getGuideCategoryByOrganization($organization_id: ID!) {\n    getGuideCategoryByOrganization(organization_id: $organization_id) {\n      id\n      name\n      icon\n      icon_web\n      sorting\n      organization_id\n      guide {\n        id\n        title\n        description\n        sorting\n        guide_category_id\n        guide_media {\n          id\n          thumbnail_link\n          video_link\n          web_thumbnail_link\n          web_video_link\n        }\n      }\n    }\n  }\n"], ["\n  query getGuideCategoryByOrganization($organization_id: ID!) {\n    getGuideCategoryByOrganization(organization_id: $organization_id) {\n      id\n      name\n      icon\n      icon_web\n      sorting\n      organization_id\n      guide {\n        id\n        title\n        description\n        sorting\n        guide_category_id\n        guide_media {\n          id\n          thumbnail_link\n          video_link\n          web_thumbnail_link\n          web_video_link\n        }\n      }\n    }\n  }\n"])));
exports.GET_GUIDE_CATEGORY = GET_GUIDE_CATEGORY;
var GET_GUIDE = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getGuideByOrganization($guide_category_id: ID!, $organization_id: ID!) {\n    getGuideByOrganization(\n      guide_category_id: $guide_category_id\n      organization_id: $organization_id\n    ) {\n      organization_id\n      id\n      sorting\n      title\n      description\n      guide_media {\n        thumbnail_link\n        video_link\n      }\n      guide_category_id\n    }\n  }\n"], ["\n  query getGuideByOrganization($guide_category_id: ID!, $organization_id: ID!) {\n    getGuideByOrganization(\n      guide_category_id: $guide_category_id\n      organization_id: $organization_id\n    ) {\n      organization_id\n      id\n      sorting\n      title\n      description\n      guide_media {\n        thumbnail_link\n        video_link\n      }\n      guide_category_id\n    }\n  }\n"])));
exports.GET_GUIDE = GET_GUIDE;
var templateObject_1, templateObject_2;
