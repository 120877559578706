"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateEducationQuestion = exports.ValidateEducationForm = void 0;
var ValidateEducationForm = function (paylaod, getCurrentLang) {
    if (JSON.parse(paylaod.title)[getCurrentLang] === '') {
        return {
            message: 'required',
            field: 'title',
            status: 'error',
        };
    }
    else if (paylaod.category === '') {
        return {
            message: 'required',
            field: 'category',
            status: 'error',
        };
    }
    else if (paylaod.versionsId.length === 0) {
        return {
            message: 'required',
            field: 'version',
            status: 'error',
        };
    }
    return {
        message: 'success',
        field: '',
        status: 'success',
    };
};
exports.ValidateEducationForm = ValidateEducationForm;
var ValidateEducationQuestion = function (payload, currentLang) {
    var _a, _b, _c, _d;
    if (payload.category === null) {
        return {
            message: 'required',
            field: 'category',
            status: 'error',
        };
    }
    else if (payload.education_id === null) {
        return {
            message: 'required',
            field: 'education',
            status: 'error',
        };
    }
    else if (payload.exam_type === null) {
        return {
            message: 'required',
            field: 'exam_type',
            status: 'error',
        };
    }
    else if (payload.data_type === null) {
        return {
            message: 'required',
            field: 'data_type',
            status: 'error',
        };
    }
    else if (JSON.parse(payload.name)[currentLang] === '') {
        return {
            message: 'required',
            field: 'name',
            status: 'error',
        };
    }
    else if ((payload.answer.length === 0 &&
        payload.exam_type &&
        typeof payload.exam_type != 'string' &&
        ((_b = (_a = payload.exam_type) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'evaluate') ||
        (payload.answer.length === 0 &&
            payload.exam_type &&
            typeof payload.exam_type === 'string' &&
            ((_d = (_c = payload.exam_type_item) === null || _c === void 0 ? void 0 : _c.label) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === 'evaluate')) {
        return {
            message: 'required',
            field: 'answer',
            status: 'error',
        };
    }
    else if (payload.answer.length > 0) {
        var errortmp_1 = [];
        payload.answer.map(function (l, i) {
            var answer_name = JSON.parse(l.answer)[currentLang];
            var answer_description = JSON.parse(l.description)[currentLang];
            if (answer_name === '') {
                errortmp_1.push({
                    type: 'name',
                    message: 'required',
                    index: i,
                });
            }
            else if (answer_description === '') {
                errortmp_1.push({
                    type: 'description',
                    message: 'required',
                    index: i,
                });
            }
            else if (l.is_correct === null) {
                errortmp_1.push({
                    type: 'isCorrect',
                    message: 'required',
                    index: i,
                });
            }
        });
        if (errortmp_1.length > 0) {
            return {
                message: errortmp_1,
                field: 'answers',
                status: 'error',
            };
        }
        else {
            return {
                message: 'success',
                field: '',
                status: 'success',
            };
        }
    }
    else {
        return {
            message: 'success',
            field: '',
            status: 'success',
        };
    }
};
exports.ValidateEducationQuestion = ValidateEducationQuestion;
