"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPSERT_FINANCIAL_STATUS_SETTING = exports.UPSERT_SUCCESS_STORY = exports.DELETE_SUCCESS_STORY = exports.DELETE_DROPDOWN_OPTIONS = exports.UPSERT_DROPDOWN_OPTIONS = exports.DELETE_FINANCIAL_INFO = exports.UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS = exports.UPSERT_FINANCIAL_INF0_INDIVIDUAL = exports.UPSERT_FINANCIAL_INFO = exports.UPSERT_FINANCIAL_CATEGORY = void 0;
var client_1 = require("@apollo/client");
var UPSERT_FINANCIAL_CATEGORY = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation upsertFinancialCategory(\n    $id: ID\n    $name: String!\n    $parent_id: ID\n    $user_type: [ID!]\n    $organization_id: ID!\n    $order_by: Int\n    $frontend_component: String\n    $link_id: ID\n  ) {\n    upsertFinancialCategory(\n      id: $id\n      name: $name\n      parent_id: $parent_id\n      user_type: $user_type\n      organization_id: $organization_id\n      frontend_component: $frontend_component\n      order_by: $order_by\n      link_id: $link_id\n    )\n  }\n"], ["\n  mutation upsertFinancialCategory(\n    $id: ID\n    $name: String!\n    $parent_id: ID\n    $user_type: [ID!]\n    $organization_id: ID!\n    $order_by: Int\n    $frontend_component: String\n    $link_id: ID\n  ) {\n    upsertFinancialCategory(\n      id: $id\n      name: $name\n      parent_id: $parent_id\n      user_type: $user_type\n      organization_id: $organization_id\n      frontend_component: $frontend_component\n      order_by: $order_by\n      link_id: $link_id\n    )\n  }\n"])));
exports.UPSERT_FINANCIAL_CATEGORY = UPSERT_FINANCIAL_CATEGORY;
var UPSERT_FINANCIAL_INFO = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation upsertFinancialInfo($input: [InfoPayload!]!) {\n    upsertFinancialInfo(input: $input)\n  }\n"], ["\n  mutation upsertFinancialInfo($input: [InfoPayload!]!) {\n    upsertFinancialInfo(input: $input)\n  }\n"])));
exports.UPSERT_FINANCIAL_INFO = UPSERT_FINANCIAL_INFO;
var UPSERT_FINANCIAL_INF0_INDIVIDUAL = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation upsertFinancialInfoIndividual($input: InfoPayload) {\n    upsertFinancialInfoIndividual(input: $input) {\n      id\n      data_type_id\n      financial_category_id\n      name\n      user_types {\n        id\n        name\n      }\n      order_by\n      data_type {\n        id\n        name\n      }\n      dependent_id\n      organization_id\n      code\n      editable\n      calculation\n      child_count\n      parent {\n        child_count\n        id\n        data_type_id\n        financial_category_id\n        name\n        user_types {\n          id\n          name\n        }\n        order_by\n        data_type {\n          id\n          name\n        }\n      }\n    }\n  }\n"], ["\n  mutation upsertFinancialInfoIndividual($input: InfoPayload) {\n    upsertFinancialInfoIndividual(input: $input) {\n      id\n      data_type_id\n      financial_category_id\n      name\n      user_types {\n        id\n        name\n      }\n      order_by\n      data_type {\n        id\n        name\n      }\n      dependent_id\n      organization_id\n      code\n      editable\n      calculation\n      child_count\n      parent {\n        child_count\n        id\n        data_type_id\n        financial_category_id\n        name\n        user_types {\n          id\n          name\n        }\n        order_by\n        data_type {\n          id\n          name\n        }\n      }\n    }\n  }\n"])));
exports.UPSERT_FINANCIAL_INF0_INDIVIDUAL = UPSERT_FINANCIAL_INF0_INDIVIDUAL;
var UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation upsertTabularOption($input: OptionPayload) {\n    upsertTabularOption(input: $input) {\n      id\n    }\n  }\n"], ["\n  mutation upsertTabularOption($input: OptionPayload) {\n    upsertTabularOption(input: $input) {\n      id\n    }\n  }\n"])));
exports.UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS = UPSERT_FINANCIAL_TABULAR_INDIVIDUAL_OPTIONS;
var DELETE_FINANCIAL_INFO = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation deleteFinancialInfo(\n    $id: ID!\n    $organization_id: ID!\n    $financial_category_id: ID!\n  ) {\n    deleteFinancialInfo(\n      id: $id\n      organization_id: $organization_id\n      financial_category_id: $financial_category_id\n    )\n  }\n"], ["\n  mutation deleteFinancialInfo(\n    $id: ID!\n    $organization_id: ID!\n    $financial_category_id: ID!\n  ) {\n    deleteFinancialInfo(\n      id: $id\n      organization_id: $organization_id\n      financial_category_id: $financial_category_id\n    )\n  }\n"])));
exports.DELETE_FINANCIAL_INFO = DELETE_FINANCIAL_INFO;
var UPSERT_DROPDOWN_OPTIONS = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  mutation upsertDropdownOptions($input: [InfoOptionPayload!]!) {\n    upsertDropdownOptions(input: $input)\n  }\n"], ["\n  mutation upsertDropdownOptions($input: [InfoOptionPayload!]!) {\n    upsertDropdownOptions(input: $input)\n  }\n"])));
exports.UPSERT_DROPDOWN_OPTIONS = UPSERT_DROPDOWN_OPTIONS;
var DELETE_DROPDOWN_OPTIONS = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  mutation deleteDropDownOptions($id: ID!) {\n    deleteDropDownOptions(id: $id) {\n      id\n    }\n  }\n"], ["\n  mutation deleteDropDownOptions($id: ID!) {\n    deleteDropDownOptions(id: $id) {\n      id\n    }\n  }\n"])));
exports.DELETE_DROPDOWN_OPTIONS = DELETE_DROPDOWN_OPTIONS;
var DELETE_SUCCESS_STORY = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  mutation deleteSuccessStory($id: ID!, $organization_id: ID!) {\n    deleteSuccessStory(id: $id, organization_id: $organization_id)\n  }\n"], ["\n  mutation deleteSuccessStory($id: ID!, $organization_id: ID!) {\n    deleteSuccessStory(id: $id, organization_id: $organization_id)\n  }\n"])));
exports.DELETE_SUCCESS_STORY = DELETE_SUCCESS_STORY;
var UPSERT_SUCCESS_STORY = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  mutation upsertSuccessStory(\n    $id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $url: [VideoInput]\n    $organization_id: ID!\n  ) {\n    upsertSuccessStory(\n      id: $id\n      title: $title\n      description: $description\n      sorting: $sorting\n      url: $url\n      organization_id: $organization_id\n    )\n  }\n"], ["\n  mutation upsertSuccessStory(\n    $id: ID\n    $title: String!\n    $description: String\n    $sorting: Int\n    $url: [VideoInput]\n    $organization_id: ID!\n  ) {\n    upsertSuccessStory(\n      id: $id\n      title: $title\n      description: $description\n      sorting: $sorting\n      url: $url\n      organization_id: $organization_id\n    )\n  }\n"])));
exports.UPSERT_SUCCESS_STORY = UPSERT_SUCCESS_STORY;
var UPSERT_FINANCIAL_STATUS_SETTING = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  mutation upsertFinancialStatusSetting(\n    $id: ID\n    $financial_info_id: ID!\n    $profit_formula: String!\n    $profit_message: String!\n    $loss_formula: String!\n    $loss_message: String!\n    $neutral_formula: String!\n    $neutral_message: String!\n    $return_on_investment: String!\n    $payback_period: String!\n    $organization_id: ID!\n  ) {\n    upsertFinancialStatusSetting(\n      id: $id\n      financial_info_id: $financial_info_id\n      profit_formula: $profit_formula\n      profit_message: $profit_message\n      loss_message: $loss_message\n      loss_formula: $loss_formula\n      neutral_message: $neutral_message\n      neutral_formula: $neutral_formula\n      return_on_investment: $return_on_investment\n      payback_period: $payback_period\n      organization_id: $organization_id\n    )\n  }\n"], ["\n  mutation upsertFinancialStatusSetting(\n    $id: ID\n    $financial_info_id: ID!\n    $profit_formula: String!\n    $profit_message: String!\n    $loss_formula: String!\n    $loss_message: String!\n    $neutral_formula: String!\n    $neutral_message: String!\n    $return_on_investment: String!\n    $payback_period: String!\n    $organization_id: ID!\n  ) {\n    upsertFinancialStatusSetting(\n      id: $id\n      financial_info_id: $financial_info_id\n      profit_formula: $profit_formula\n      profit_message: $profit_message\n      loss_message: $loss_message\n      loss_formula: $loss_formula\n      neutral_message: $neutral_message\n      neutral_formula: $neutral_formula\n      return_on_investment: $return_on_investment\n      payback_period: $payback_period\n      organization_id: $organization_id\n    )\n  }\n"])));
exports.UPSERT_FINANCIAL_STATUS_SETTING = UPSERT_FINANCIAL_STATUS_SETTING;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
