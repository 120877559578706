"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportValidate = void 0;
var general = function (payload) {
    var fromDate = replaceData(payload.from);
    var toDate = replaceData(payload.to);
    if (!payload.from) {
        return {
            status: 'error',
            field: 'from',
            message: 'From date cannot be empty',
        };
    }
    else if (!payload.to) {
        return {
            status: 'error',
            field: 'to',
            message: 'To date cannot be empty',
        };
    }
    else if (fromDate >= toDate) {
        return {
            status: 'error',
            field: 'from',
            message: 'End date must be greater than Start date',
        };
    }
    else {
        return {
            status: 'success',
            message: 'success',
            field: null,
        };
    }
};
var MyFinanceReport = function (payload) {
    var fromDate = replaceData(payload.from);
    var toDate = replaceData(payload.to);
    if (!payload.from) {
        return {
            status: 'error',
            field: 'from',
            message: 'From date cannot be empty',
        };
    }
    else if (!payload.to) {
        return {
            status: 'error',
            field: 'to',
            message: 'To date cannot be empty',
        };
    }
    else if (fromDate >= toDate) {
        return {
            status: 'error',
            field: 'from',
            message: 'End date must be greater than Start date',
        };
    }
    else if (payload.report_type === '') {
        return {
            status: 'error',
            field: 'report_type',
            message: 'Report type is required',
        };
    }
    else {
        return {
            status: 'success',
            message: 'success',
            field: null,
        };
    }
};
var replaceData = function (value) {
    var valueString = value.toString();
    var valueUpdated = new Date(valueString.replace(/-/g, '/'));
    return valueUpdated;
};
exports.reportValidate = { general: general, MyFinanceReport: MyFinanceReport };
